import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ResumeDialog({ open, toggleResume }) {
  const resumeEmbedLink =
    "https://onedrive.live.com/embed?resid=73CC45EDB90C9D7%21108&authkey=!AK8mDvAH6-1e9dU&em=2";

  return (
    <Dialog
      divider={false}
      open={open}
      size={"xl"}
      handler={toggleResume}
      className="outline-none rounded-none overflow-y-auto"
      style={{ maxHeight: "95%" }}
    >
      <DialogHeader className="h-20 relative flex justify-center items-center bg-black">
        <h1 className="font-mono text-white text-5xl mx-auto font-bold shadow-lg">
          Resume
        </h1>
        <button
          onClick={toggleResume}
          className="absolute right-0 top-0 mt-5 mr-5 z-10 text-white cursor-pointer outline-none"
          aria-label="Close Dialog"
        >
          X
        </button>
      </DialogHeader>

      <DialogBody className="flex flex-col items-center">
        <div className="w-full flex justify-center">
          <iframe
            src={resumeEmbedLink}
            width="100%"
            height="600px"
            className="border-0"
            allow="autoplay"
            style={{ display: "block", margin: "0 auto" }}
          ></iframe>
        </div>
        <Button
          onClick={() => window.open(resumeEmbedLink, "_blank")}
          className="mt-4"
        >
          Download Resume
        </Button>
      </DialogBody>
    </Dialog>
  );
}
