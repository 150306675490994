import EmailIcon from "@mui/icons-material/Email";
import { useTheme } from "@mui/material/styles";
function Email() {
  const theme = useTheme();
  return (
    <div>
      <EmailIcon sx={{ fontSize: 47, color: theme.palette.common.white }} />
    </div>
  );
}

export default Email;
