import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import emailjs from "emailjs-com";

export function EmailDialog({ open, toggleEmail }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      to_name: email,
      message,
    };

    emailjs
      .send(
        "service_rr2l53v",
        "template_wenwpzm",
        templateParams,
        "3iMrTHO2ttFoCNyGD"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
          setName("");
          setEmail("");
          setMessage("");
        },
        (err) => {
          console.log("FAILED...", err);
          alert("Failed to send message, please try again later.");
        }
      )
      .finally(() => {
        toggleEmail();
      });
  };

  return (
    <>
      <Dialog className="" size={"md"} open={open} handler={toggleEmail}>
        <DialogHeader className="h-20 relative flex justify-center items-center bg-black">
          <h1 className="font-mono text-white text-5xl mx-auto font-bold shadow-lg">
            Email Me
          </h1>
          <button
            onClick={toggleEmail}
            className="absolute right-0 top-0 mt-5 mr-5 z-10 text-white cursor-pointer outline-none"
            aria-label="Close Dialog"
          >
            X
          </button>
        </DialogHeader>
        <DialogBody
          className="border-none flex-col items-center w-full"
          divider
        >
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-auto">
            <h2 className="text-2xl font-bold mb-4 text-center">Contact Me</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send
                </button>
                <button
                  type="button"
                  onClick={toggleEmail}
                  className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default EmailDialog;
