import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import { ProjectsDialog } from "./components/ProjectsDialog";
import { ResumeDialog } from "./components/ResumeDialog";
import GitHub from "./svg/GitHub";
import Email from "./svg/Email";
import Projects from "./svg/Projects";
import Resume from "./svg/Resume";
import About from "./svg/About";
import AboutMe from "./components/AboutMe";
import EmailDialog from "./components/EmailDialog";

function SvgContainer() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAbout, setDialogAboutOpen] = useState(false);
  const [dialogResume, setDialogResume] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const [key, setKey] = useState(0);
  const toggleDialog = () => setDialogOpen((prev) => !prev);
  const toggleAbout = () => setDialogAboutOpen((prev) => !prev);
  const toggleResume = () => setDialogResume((prev) => !prev);
  const toggleEmail = () => setEmailDialog((prev) => !prev);

  const animationProps = useSpring({
    from: { opacity: 0, marginTop: 50 },
    to: { opacity: 1, marginTop: 0 },
    reset: true,
    reverse: !currentTitle,
    key: key,
    config: config.stiff,
  });

  const handleMouseEnter = (title) => {
    setCurrentTitle(title);
    setKey((prevKey) => prevKey + 1);
  };

  const handleMouseLeave = () => {
    setCurrentTitle("");
    setKey((prevKey) => +1);
  };

  const handleClick = (title) => {
    if (title === "Projects") {
      toggleDialog();
    } else if (title === "GitHub") {
      window.open("https://github.com/alantothe", "_blank");
    } else if (title === "Email") {
      toggleEmail();
    } else if (title === "Resume") {
      toggleResume();
    } else if (title === "About Me") {
      toggleAbout();
    }
  };

  const svgs = [
    { id: 1, title: "GitHub", Component: GitHub },
    { id: 2, title: "Email", Component: Email },
    { id: 3, title: "Projects", Component: Projects, pulse: true },
    { id: 4, title: "Resume", Component: Resume },
    { id: 5, title: "About Me", Component: About },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-wrap justify-center space-x-4">
        <AboutMe open={dialogAbout} toggleAbout={toggleAbout} />
        <ResumeDialog open={dialogResume} toggleResume={toggleResume} />
        <ProjectsDialog open={dialogOpen} toggleDialog={toggleDialog} />
        <EmailDialog open={emailDialog} toggleEmail={toggleEmail} />
        {svgs.map(({ id, title, Component, pulse }) => (
          <IconWithHoverEffect
            key={id}
            title={title}
            Component={Component}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleClick={handleClick}
            pulse={pulse}
          />
        ))}
      </div>
      <div className="h-10 flex justify-center items-center mt-4">
        <animated.h1
          style={animationProps}
          className="text-white text-2xl font-mono font-thin transition-opacity duration-500 ease-in-out"
        >
          {currentTitle || " "}
        </animated.h1>
      </div>
    </div>
  );
}

const IconWithHoverEffect = ({
  title,
  Component,
  handleMouseEnter,
  handleMouseLeave,
  handleClick,
  pulse,
}) => {
  const [isHovered, setHovered] = useState(false);
  const hoverProps = useSpring({
    transform: isHovered ? "scale(1.2)" : "scale(1)",
    config: config.wobbly,
  });

  const pulseProps = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.1)" },
    loop: { reverse: true },
    config: { duration: 1000 },
  });

  return (
    <animated.div
      className={`w-16 h-16 cursor-pointer p-4 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
        isHovered
          ? "bg-gradient-to-r from-gradientStart to-gradientEnd"
          : "bg-gray-800 text-white"
      }`}
      onMouseEnter={() => {
        handleMouseEnter(title);
        setHovered(true);
      }}
      onMouseLeave={() => {
        handleMouseLeave();
        setHovered(false);
      }}
      onClick={() => handleClick(title)}
      style={{ ...hoverProps, ...(pulse ? pulseProps : {}) }}
    >
      <Component
        className={`w-8 h-8 ${isHovered ? "text-white" : "text-white"}`}
        color={isHovered ? "white" : "white"}
      />
    </animated.div>
  );
};

export default SvgContainer;
