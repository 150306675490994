import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ProjectsDialog({ open, toggleDialog }) {
  return (
    <Dialog
      divider={false}
      open={open}
      size={"xl"}
      handler={toggleDialog}
      className="outline-none rounded-none overflow-y-auto"
      style={{ maxHeight: "95%" }}
    >
      <DialogHeader className="h-20 relative flex justify-center items-center bg-black">
        <h1 className="font-mono text-white text-5xl mx-auto font-bold shadow-lg">
          Projects
        </h1>
        <button
          onClick={toggleDialog}
          className="absolute right-0 top-0 mt-5 mr-5 z-10 text-white cursor-pointer outline-none"
          aria-label="Close Dialog"
        >
          X
        </button>
      </DialogHeader>

      <DialogBody>
        {/* Chat App */}
        <div
          className="flex flex-col justify-evenly gap-4 m-4 text-black"
          style={{
            fontFamily: "'Sudbury Book', sans-serif",
          }}
        >
          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-8 items-center sm:items-center md:items-start lg:items-start">
            <div className="flex flex-col w-3/4 sm:w-3/4 md:w-1/4 lg:w-1/4 gap-4">
              <a
                href="https://github.com/alantothe/chat_app_client_next"
                target="_blank"
                className="outline-none"
              >
                <h1 className="text-center text-2xl sm:text-2xl md:text-xl lg:text-xl hover:opacity-50 w-full">
                  Chat App
                </h1>
                <img
                  src="https://res.cloudinary.com/dzjr3skhe/image/upload/v1695398677/Screen_Shot_2023-09-22_at_12.04.24_PM_zqyblg.png"
                  alt="Wine About It"
                  className="cursor-pointer hover:opacity-50 object-contain w-full"
                ></img>
              </a>
            </div>

            <div className="flex flex-col w-3/4 mr-4">
              <div
                className="flex justify-between text-sm mobile:text-xs"
                style={{ color: "rgb(102, 101, 171)" }}
              >
                <a
                  href="https://github.com/alantothe/chat_app_client_next"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  In Development
                </a>
                <a
                  href="https://github.com/alantothe/chat_app_client_next"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Client Repository
                </a>
                <a
                  href="https://github.com/alantothe/chat_app_server"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Server Repository
                </a>
              </div>
              <p className="flex-grow items-center text-sm mt-2 break-words w-full">
                Currently developing a chat application with real-time
                capabilities, leveraging TypeScript, Next.js, Redux, Socket.io,
                Tailwind CSS, MongoDB, and Express. This project showcases
                advanced skills and knowledge. At its core, the platform
                features real-time communication powered by Socket.io, enabling
                users to engage in direct messages and group chats with zero
                latency. Additionally, the app includes a robust friend request
                system, dynamic notification system, and personalized avatars.
                Technologies like Dropzone and the Cloudinary API ensure
                seamless image uploads and efficient image link storage. While
                still in development, a fully functional prototype is already
                operational. Future enhancements will include customizable
                profiles, an online status indicator, and the ability to send
                images in messages. To see the progress, please visit the GitHub
                repository{" "}
                <a
                  href="https://github.com/your-repo-link"
                  className="underline"
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
          {/* Wine */}
          <hr
            style={{ backgroundColor: "rgb(102, 101, 171)", height: "2px" }}
          ></hr>
          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-8 items-center sm:items-center md:items-start lg:items-start">
            <div className="flex flex-col w-3/4 sm:w-3/4 md:w-1/4 lg:w-1/4 gap-4">
              <a
                href="https://vino.alanwebdev.com/"
                target="_blank"
                className="outline-none"
              >
                <h1 className="text-center text-2xl sm:text-2xl md:text-xl lg:text-xl hover:opacity-50 w-full">
                  Le Vino
                </h1>
                <img
                  src="https://res.cloudinary.com/dzjr3skhe/image/upload/v1697393495/Screen_Shot_2023-10-15_at_10.08.19_AM_jx9px4.png"
                  alt="Le Vino"
                  className="cursor-pointer hover:opacity-50 object-contain"
                ></img>
              </a>
            </div>

            <div className="flex flex-col w-3/4 mr-4">
              <div
                className="flex justify-between text-sm mobile:text-xs"
                style={{ color: "rgb(102, 101, 171)" }}
              >
                <a
                  href="https://vino.alanwebdev.com/"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  Deployed App
                </a>
                <a
                  href="https://github.com/alantothe/wine-about-it-personal-client"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Client Repository
                </a>
                <a
                  href="https://github.com/alantothe/wine-about-it-personal-server"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Server Repository
                </a>
              </div>
              <p className="flex-grow items-center text-sm mt-2 break-words w-full">
                During the development of a project titled "Wine About It," my
                team and I created a platform that allowed users to seamlessly
                sign up, browse wines alphabetically, by type, or by price
                range. They could mark their favorite wines, fill their carts,
                and proceed to checkout. Users could also revisit and review
                their order details after making a purchase. I am now excited to
                present an enhanced version of this project, aptly named "Le
                Vino." This improved version builds upon the foundation of "Wine
                About It" with new features and upgrades. I've incorporated
                features such as wine search capabilities, refined shopping cart
                functionality, and better CSS responsiveness. In the original
                project, I collaborated with three talented developers and was
                responsible for the backend architecture. Once the backend was
                established, my attention shifted to supporting and guiding the
                team on the frontend. During this phase, I introduced and
                oversaw the use of technologies like Redux and Tailwind CSS. One
                of the major improvements in "Le Vino" is the refined shopping
                cart functionality. Utilizing Redux, users can now easily add
                and remove individual items from their cart. If you're curious
                about our original work, feel free to visit it{" "}
                <span>
                  <a
                    target="_blank"
                    className="text-blue underline"
                    href="https://vino.alanwebdev.com/"
                  >
                    here
                  </a>
                </span>
                .
              </p>
            </div>
          </div>

          <hr
            style={{ backgroundColor: "rgb(102, 101, 171)", height: "2px" }}
          ></hr>

          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-8 items-center sm:items-center md:items-start lg:items-start">
            <div className="flex flex-col w-3/4 sm:w-3/4 md:w-1/4 lg:w-1/4 gap-4">
              <a
                href="https://youandmeme.netlify.app/"
                target="_blank"
                className="outline-none"
              >
                <h1 className="text-center text-2xl sm:text-2xl md:text-xl lg:text-xl hover:opacity-50 w-full">
                  You & Meme
                </h1>
                <img
                  src="https://res.cloudinary.com/dzjr3skhe/image/upload/v1695365491/Screen_Shot_2023-09-22_at_1.53.30_AM_p4skcd.png"
                  alt="You and Meme"
                  className="cursor-pointer hover:opacity-50 object-contain"
                ></img>
              </a>
            </div>

            <div className="flex flex-col w-3/4 mr-4">
              <div
                className="flex justify-between text-sm mobile:text-xs"
                style={{ color: "rgb(102, 101, 171)" }}
              >
                <a
                  href="https://youandmeme.netlify.app/"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  Deployed App
                </a>
                <a
                  href="https://github.com/manfredjoa/you-and-meme-client"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Client Repository
                </a>
                <a
                  href="https://github.com/manfredjoa/you-and-meme-server"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Server Repository
                </a>
              </div>
              <p className="flex grow items-center text-sm mt-2">
                In the final week of our 12-week Software Engineering Immersive
                at General Assembly, my team introduced "You & Meme", a
                Django-React (DR) stack-based social media platform. As the team
                leader among six developers, we strived to build a user-friendly
                interface. Users can establish personalized accounts with unique
                avatars, design memes from a variety of templates, and actively
                engage with others by commenting and liking posts. One of the
                standout features of our platform is the integration with an API
                that allows users to generate custom text on a wide array of
                meme templates. The platform provides a seamless navigation
                experience with a home page, detailed post views, and user
                profiles.
              </p>
            </div>
          </div>

          <hr
            style={{ backgroundColor: "rgb(102, 101, 171)", height: "2px" }}
          ></hr>

          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row gap-8 items-center sm:items-center md:items-start lg:items-start">
            <div className="flex flex-col w-3/4 sm:w-3/4 md:w-1/4 lg:w-1/4 gap-4">
              <a
                href="https://alantothe.github.io/Blackjack_OOP/"
                target="_blank"
                className="outline-none"
              >
                <h1 className="text-center text-2xl sm:text-2xl md:text-xl lg:text-xl hover:opacity-50 w-full">
                  Blackjack (OOP)
                </h1>
                <img
                  src="https://raw.githubusercontent.com/alantothe/Blackjack_OOP/main/pictures/screenshot.png"
                  alt="BlackJack"
                  className="cursor-pointer hover:opacity-50 object-contain"
                ></img>
              </a>
            </div>

            <div className="flex flex-col w-3/4 mr-4">
              <div
                className="flex justify-between text-sm mobile:text-xs"
                style={{ color: "rgb(102, 101, 171)" }}
              >
                <a
                  href="https://alantothe.github.io/Blackjack_OOP/"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  Deployed App
                </a>
                <a
                  href="https://github.com/alantothe/Blackjack_OOP"
                  target="_blank"
                  className="text-center cursor-pointer hover:opacity-50"
                >
                  GitHub Repository
                </a>
              </div>
              <p className="flex-grow items-center text-sm mt-2 break-words w-full">
                Introducing Blackjack, a dynamic and engaging blackjack game
                developed with Object-Oriented Programming principles. This
                project demonstrates the integration of core OOP concepts to
                create an interactive and visually appealing game experience.
                Players start with a balance and can place bets, hit, stand, or
                deal, aiming to get a hand value as close to 21 as possible
                without exceeding it. The game interface displays the player's
                balance, current bet, and the cards dealt, making it easy to
                keep track of the game state. Additionally, the game features a
                clean and intuitive design, enhancing user experience. The
                styling is achieved using vanilla CSS, ensuring a lightweight
                and fast-loading interface that provides a seamless gaming
                experience. To explore the code and see the project in action,
                visit the GitHub repository{" "}
                <a
                  href="https://github.com/alantothe/Blackjack_OOP"
                  className="underline"
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
}
