import ParticlesBackground from "../ParticlesBackground/ParticlesBackground";
import Alan from "../Page1/Alan";
import SvgContainer from "../Page1/SvgContainer";

function Main() {
  return (
    <div className="flex items-center justify-center h-screen relative overflow-hidden bg-gray-900">
      <ParticlesBackground />

      <div className="z-10 flex flex-col items-center">
        <Alan />
        <SvgContainer />
      </div>
    </div>
  );
}

export default Main;
